
.card-header .fa {
  transition: .3s transform ease-in-out;
}
.card-header .collapsed .fa {
  transform: rotate(90deg);
}

.divbody{

  background-color: #f5f5f5;
}

.lefter{
 
 -ms-flex: 0 0 260px;
  flex: 0 0 260px;
  min-width: 260px !important;
  max-width:260px !important;
  
  background-color:#0567AD !important;
  min-height:100vh;
  height:100% !important;
  color:white ;
  


}


.leftbarcloseicon{

  color:#0567AD;
  margin-top: 9px !important;
  font-size: 1.5rem;
  font-weight:bolder;
  margin-right:10px;
}

.leftbardropdownmenuicon{
  color:#0567AD !important;
  margin-top: 6px !important;
  font-size: .9rem !important;
  font-weight:bold !important;
  background-color: white !important;
  height:30px !important;
  padding:0px !important;
  border-style:none !important;
  display:none !important;
  font-family: "Open Sans", sans-serif !important;
 
}

.leftbardropdownmenuicon:hover {
  background-color: white !important;
  border-width: .5px !important;
  border-color:#0567AD !important ;
  border-style:solid !important
}

.leftmenudropdowncontents{
  background-color:#0567AD !important;
  color:white !important ;
  border-radius:7px !important;
  width:260px !important;
}

.dropdown-item{
  background-color:#0567AD !important ;
  font-size:.8rem !important;
  color:white !important;
}

.dropdown-item:hover{
  font-size:.9rem !important;
  color:rgb(234, 234, 234)!important;

}


.leftnav{
  margin-top:10px;
}

.navlinebreak{
border-color:white;
opacity: 100;
border-width:.75;

  margin:0px;
  width:90%;


}

.lefter  ul li a {
  color:rgb(234, 234, 234);
  font-size:.85rem;
  padding:4 !important;


}

.navgroupheader {
  color:rgb(255, 255, 255);
  font-size:.86rem;
  margin-bottom:5px;
  margin-Top:10px;
  font-weight: 600;

}

.navfooter{
  width:90%;
  background-color:rgb(59, 94, 169) ;
  color:white;
  border:.5px solid white;
  border-radius:5px;

  font-size:.9rem;
  padding:5px;
  text-align:center;
  padding:5px;

  
}

.lefter   a:hover{
  color:rgb(255, 255, 255);
  font-size:.89rem;
  font-weight: 500;
  opacity: 100;
}



.topbar {
  background-color:rgb(253, 253, 253) !important;
  height:40px;
  color:rgb(38,38,38);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.05);
  padding:0px !important;

}

.topbar_leftcolumn{
  margin-left:10px !important;
  margin-right:0px ! important;
  padding-right:0px !important;
  padding-left:10px !important
}

.topbar_rightcolumn{
  margin-right:10px !important;
  text-align: right !important;
  padding:0px !important;
  padding-right:10px !important;

}

.topiconhodler {
  text-align: right !important;

  margin-top: 17px !important;
  color:#434343;
  font-size:1.2rem;
  font-weight: 600;

}

.topicons {
  margin-left:10px;
  margin-top: 9px !important;
  padding-top:0px !important;
  font-size:1.3rem ;
  /*color:gray !important;*/
  text-decoration: none !important;


}

.topbarlogo{

  height:25px;
}


.main{

  max-height:100vh;
  overflow-y:scroll;
}


.params{
  margin-top:5px;
  
  
}

.players{
  width:18rem;
}

@media(max-width:897px){
  .lefter{
     
   display:none;
    
  }
  
  .lefttop{
    display:block;
  }
  
.topbarlogo{
  display:none;
  width:0px;
  padding:0px;
  margin:0px;
}

  
  .players{
    width:100%;
  }

  .leftbarcloseicon{
      display: none;
  }
  
  .toplogictitle{
      font-size:.7rem;
  }

  .topiconhodler{
      margin-right: 5px;
  }
  
  .leftbardropdownmenuicon{
      display:block !important;
  }
  
}