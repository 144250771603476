.purposeCard{

font-size:1.1rem !important;
border: 0px none !important;
background-color:transparent !important;
border:none !important;


}


.purposeHeader{

    background-color: transparent !important;
    padding:0px !important;
    margin:0px !important;
    text-align: center !important;

    font-size:1.8rem !important;
    font-weight: bold !important;
    border: 0px solid gray !important;
    border-bottom:.5px solid gray !important;
   /* text-shadow: 1px 2px 3px rgba(0,0,0,0.1) !important;*/
  
}

.cardContent{

   
    font-size:.9rem !important;
   
    margin-left:60px !important;
    margin-top:20px;
    display:block !important;
}


.sectionHeading{
    display:block;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
   
    /*text-shadow: 1px 2px 3px rgba(0,0,0,0.1);*/
   
}

.featureCard{

    min-width: 250px !important;
    margin-bottom:8px !important;
    height:200px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.featureCardHeader {
    color: white !important;
    text-align: center;
    font-size: 1.0rem;
    font-weight: bold !important;
    background-color: #474545 !important;
    background-color:#0567AD !important;
  }

  .featureHeaderIcon {
    font-size: 1.5rem !important;
    text-align: center !important;
    margin-right: 5px !important;
  }

  .featureCardTitle {
    text-align: center !important;
    font-size: 0.9rem !important;
    font-weight: bold !important;
    border-bottom: 0.5px solid #b0aeae !important;
    padding-bottom: 3px !important;
  }

  .featureCardText{
    text-align: center !important;
    font-size: .83rem !important;

  }

  .contactCardHeader{
    color: white !important;
    text-align: center;
    font-size: 1.0rem;
    font-weight: bold !important;
    background-color: #474545 !important;
    background-color: #4E8542 !important;

   
  }