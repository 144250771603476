@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.pricing7 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.pricing7 h1,
.pricing7 h2,
.pricing7 h3,
.pricing7 h4,
.pricing7 h5,
.pricing7 h6 {
  color: #3e4555;
}

.pricing7 .font-weight-medium {
  font-weight: 500;
}

.pricing7 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.pricing7 h5 {
  line-height: 22px;
  font-size: 18px;
}

.pricing7 .pricing-box sup {
  top: -10px;
  font-size: 16px;
}

.pricing7 .pricing-box .btn {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.pricing7 .text-info {
  color: #188ef4 !important;
}

.pricing7 .display-6 {
  font-size: 36px;
}

.pricing7 .display-5 {
  font-size: 3rem;
}

.pricing7 .btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(legacy-direction(to right), #188ef4 0%, #316ce8 100%);
  background: -webkit-linear-gradient(linear, left top, right top, from(#188ef4), to(#316ce8));
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.pricing7 .btn-info-gradiant:hover {
  background: #316ce8;
  background: -webkit-linear-gradient(legacy-direction(to right), #316ce8 0%, #188ef4 100%);
  background: -webkit-linear-gradient(linear, left top, right top, from(#316ce8), to(#188ef4));
  background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.pricing7 .btn-md {
  padding: 15px 45px;
  font-size: 16px;
}