#login-form {
    width: 400px;
    max-width: 100%;
    margin: 50px auto;
    background-color: #999898;
    border-radius: 10px;
    /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);*/
    /*font-family: "Poppins", sans-serif;*/
    border-width:.5px;
    border-style:solid;
    
    
  }
  
  #login-form h2 {
    text-align: center;
    margin: 0;
    padding: 20px 0;
    font-size: 20px;
    font-weight: bold;
    color:gray;
  }
  
  #login-form form {
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    /*font-family: "Poppins", sans-serif;*/
    text-align: center;
  }
  
  #login-form form label {
    display: block;
    margin-bottom: 8px;
    font-size: 10px;
    color: gray;
    /*font-family: "Poppins", sans-serif;*/
  }
  
  #login-form form input[type="text"],
  #login-form form input[type="password"] {
    width: 100%;
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 12px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  #login-form form input[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: rgb(104, 104, 104);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  #login-form form input[type="submit"]:hover {
    background-color: rgb(48, 51, 52);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }



  #fp{

    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: gray;
    /*font-family: "Poppins", sans-serif;*/
    
   
  }

  #fp a {
    
    display: block;
    margin-bottom: 3px;
    margin-top: 15px;
    font-size: 14px;
    color: gray;
    font-family: "Poppins", sans-serif;
    
   
  } 
  