input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.v2_playpos{
    width:175px;
    margin-right:5px;
    margin-top:10px;
    border-width: .3px;
    border-color:lightgray;
    border-style: solid;
    border-radius:5px 5px 0px 0px ;
   
}

.v2_panelheader {
    height:20px;
    width:100%;
    text-align: center;
    background-color: #0567AD;
    color:white;
    vertical-align: middle;
   margin:0 auto;
    
}
.myCards{
  margin-top:5px !important;
}

.CardHeaderGreen{
  height: 30px !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  text-align: center !important;
  background-color:  #4E8542 !important ;
  color: white !important;

}



.CardHeaderBlue{
  height: 30px !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  text-align: center !important;
  background-color: #0567AD !important ;
  color: white !important;

}

.CardHeaderDarkBlue{
  height: 30px !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  text-align: center !important;
  background-color: #002C59 !important ;
  color: white !important;

}

.CardHeaderButton{
  background-color: transparent !important;
  border-style:none !important;
  width:100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height:100% !important;
  font-weight:600;
  font-size:.9rem !important;


}




.cHeaderButton{
    height:40px;
    width:100%;
    text-align: center;
    background-color: #0567AD !important;
    color:white;
    vertical-align: middle;
   margin:0 auto;
   border-radius: 5px 5px 0px 0px !important;
   padding:0px !important;
   font-size:.8rem !important;
   
 
   
}




.cHeaderButton:hover {
    
    background-color: #3d68ac !important;
   
   
}

.v2_playpos_inputs{
    width:100%;
    height:100%;
text-align: center;
    vertical-align: middle;
    height:100%;
    margin: 0 auto;
    margin-top:4px;
}

.v2_playpos_inputs form{
    margin:0 auto !important;
    display: inline-block !important;
}

.v2_playpos_Grid{
    height:300px;

}

.v2_ratcategory{
    width:98%;
    margin:0 auto;
    position:relative;
  
    border-color:#103775;
    border-radius: 5px;
    height:20px;
    margin-top:5px;
    margin-bottom: 5px;
    text-align: center;
    vertical-align: middle;
    line-height:20px;
    font-size:.8rem !important;
    color:#103775;
    font-weight: bold;
    background-color:#ffffff;
    font-family: "Open Sans", sans-serif !important;
    
    
    

}

.ag-theme-alpine{
    --ag-cell-horizontal-padding:5px !important;
    padding:5px !important;
    height:98%;
    width:98%;
    

    
}


.ag-row .ag-cell {
      
    align-items: center;
    font-family: "Open Sans", sans-serif !important;
    font-size:.7rem ;
    
  
      border-style:none;
      border-color:#000000;
      
   
  }


.cusGrid {
    --ag-borders: none;
    /*--ag-row-border-width:0px;*/
    /*--ag-row-border-radius:5px;*/
    /*--ag-row-hover-border-radius: 5px;*/
   
    
  }



  .fsinput{
    margin:0px;
    border-radius: 4px;
    border-width:.5px;
    margin-left:4px;
    font-size:.75rem !important;
    height:20px;
    width:130px;
    margin-top: 4px;
    font-family: "Open Sans", sans-serif !important;
  
}
.fsbutton{
  
    margin:0 auto;
    margin-left:5px;
 
    cursor:pointer;
    background-color: transparent;
    border: 0px;
    color:green;
    font-size: .8rem !important;
    vertical-align: middle;
  

}

.executeModelButton{

  cursor: pointer;
  background-color: #4E8542;
  border-style: none;
  color: white;
  font-size: 0.8rem !important;
  vertical-align: middle;
  margin-left: 5px;
  height: 30px;
  border-radius: 3px;
  font-weight: normal;
}

.executeModelButton:hover{

  background-color: #7FB069;
}

.executeModelButton:active {
  transform: scale(0.96); 
  background-color: #2E5131;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}

.griddeletebutton{
    cursor: pointer;
    background-color: transparent;
    border: 0px;
    color:darkred;
    font-size: .9rem !important;
    margin:0px;
    
  
   
   
}


.v2_rating{
    width:195px;
    margin-right:5px;
    margin-top:10px;
    border-width: .3px;
    border-color:lightgray;
    border-style: solid;
   
}

.v2_results{
    width:325px;
    margin-right:5px;
    margin-top:10px;
    border-width: .3px;
    border-color:lightgray;
    border-style: solid;
    
}








.v2_params_inputs{
   display:flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top:4px;
     

}

.v2_params_form{
    width:50% !important;
}

.v2_params_inputbox{
    width:150 !important;
    margin: auto !important;
    min-width: 0 !important;
    height:20px !important;
    font-size:.8rem !important;
}

.inputlabels{
    font-size:.8rem !important;
}





fieldset, legend,form,select  {
    all: revert;
  }
  
  .allContent{
  color:purple;
    -webkit-animation: opacity 5s ease-in !important; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 5s ease-in !important; /* Firefox < 16 */
     -ms-animation: fadein 5s ease-in !important; /* Internet Explorer */
      -o-animation: fadein 5s ease-in !important; /* Opera < 12.1 */
         animation: fadein 5s ease-in !important;
  }
  
  
  .flex-container {
      display: flex;
      justify-content: center;
      background-color: #f7f7f7;
      
    }
    .ratingCategory{
        border-style: solid;
        border-width: .5px;
        border-color:#103775;
        border-radius: 5px;
        height:40px;
        position: relative;
        margin: 0 auto;
        margin-top:5px;
        margin-bottom: 15px;
        width: 250px;
        text-align: center;
        vertical-align: middle;
        line-height:40px;
        font-size:1rem !important;
        color:#103775;
        font-weight: bold;
        background-color:#ffffff;
        font-family: "Open Sans", sans-serif !important;
        
    }
    









    @media screen and (max-width: 769.98px) {
      .flex-container{
        flex-direction: column;
      }

      .v2_playpos{
        width:95%;
        margin-right:10px;
        margin-top:4px;
        border-width: .3px;
        border-color:lightgray;
        border-style: solid;                 
        margin-Left:10px;
       
    }

    .v2_playpos_Grid{
        height:250px;
    
    }

    .v2_rating{
        width:95%;
        margin-right:10px;
        margin-top:4px;
        border-width: .3px;
        border-color:lightgray;
        border-style: solid;
        margin-Left:10px;
       
    }

    .v2_results{
        width:95%;
        margin-right:10px;
        margin-top:4px;
        border-width: .3px;
        border-color:lightgray;
        border-style: solid;
        margin-left:10px;
       
    }
  
     
    }
  






  .Header{
      height:50px;
      width: 100%;
      border-radius:0px;
      border-style: solid;
      border-width:1px;
      border-color: lightgray;
      font-size:1rem !important;
      color: white;
      vertical-align: middle;
      margin:0px;
      line-height: 50px;
      padding: 0px 0px 0px 0px;
      background-color: #ffffff;
  }
  
  .ActionHeader{
      
      background-color:#f6f6f6;
      width:100%;
      color:white;
      height:70px;
      display:flex;
      justify-content:center;
      border-color:#8f8f8f;
      border-width:0px 0px .5px 0px;
      border-style:solid;
      
  }
  
  .divAnalysis{
      width: 90%;
      border-style: solid;
      border-width:.5px;
      border-color:lightgray;
  
  
  }
  
  .ActionHeader label{
      font-size:.8rem !important;
      color: rgb(0, 0, 0);
     
  
  } 
  
  .ActionInputDiv{
      
      margin:auto 5px;
  }
  
  .panelDiv{
  border-width:1px;
  border-color: lightgray;
  height: 400px;
  width: 300px;
  border-radius:10px;
  border-style: solid;
  margin: 10px;
  box-shadow: 5px 5px 10px #eae9e9;
  margin-top:10px;
  
  /*float:left;*/
  
  }
  
  .panelDiv-Header{
  width:300px;
  height:35px;
  
  background-color: #103775;
  background-color: #6372ff;
  background-color:#0567AD;
  
  
  color:white;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  border-style: solid;
  border-width: 0px;
  line-height:35px;
  
  font-size:  .8rem !important;
  vertical-align:middle;
  font-weight: bold;
  font-family: "Open Sans", sans-serif !important;
  
  }
  
  .panelDiv-Icon{
  margin: 0px 10px 0px 0px;
  vertical-align: middle;
  font-size: .8rem !important;
  
  }
  
  .addItems{
  
  border-style:solid;
  border-width: 1px;
  border-radius: 5px;
  height: 40px;
  width:230px;
  border-color:rgb(205, 202, 202);
  text-align: center;
  color: #000000;
  font-size: .8rem !important;
  position: relative;
  margin: 0 auto;
  margin-top:5px;
  
  }
  

  
  .fsinputAction{
      margin:0px;
      border-radius: 4px;
      border-width:1px;
      margin-left:6px;
      font-size:.8rem !important;
      height:20px;
      width:50px;
      margin-top: 6px;
      margin-right:3px;
      text-align: center;
      border-color:lightgray;
      font-family: "Open Sans", sans-serif !important;
  
  }
  
  .fsinputlabelAction{
      margin:5px;
      font-family: "Open Sans", sans-serif !important;
  
  }
  
  .addItemsAction{
  
      border-style:solid;
      border-width: 1px;
      border-radius: 8px;
      height: 40px;
      width:200px;
      border-color:rgb(205, 202, 202);
      text-align: center;
      color: #000000;
      font-size: .8rem !important;
      position: relative;
      margin: 0 auto;
      
      
      }
  

  
  .addItemsLegend{
      color: #7d7d7d;
      font-size: .8rem !important;
      font-family: "Open Sans", sans-serif !important;
      
   
  }
  
  .addItemsLegendAction{
      color: #000000;
      font-size: .8rem !important;
      font-family: "Open Sans", sans-serif !important;
   
  }
  
  .ratingCategory{
      border-style: solid;
      border-width: .5px;
      border-color:#103775;
      border-radius: 5px;
      height:40px;
      position: relative;
      margin: 0 auto;
      margin-top:5px;
      margin-bottom: 15px;
      width: 250px;
      text-align: center;
      vertical-align: middle;
      line-height:40px;
      font-size:.8rem !important;
      color:#103775;
      font-weight: bold;
      background-color:#ffffff;
      font-family: "Open Sans", sans-serif !important;
      
  }
  
  .gridDiv{
  padding:5px;
  text-align:center;
  height:300px;
  width:95%;
  
  }
  
 
  .cusEditCell{
  color:black;
  border-left:solid .5px;
  border-right:solid .5px;
  text-align: center;
  align-items: center;
  font-size:10px !important;
  height:18px !important;
  line-height: 18px !important;
 

  
  
  }
  
  
  

    .ag-paging-panel {
      float: right;
      font-size:.8rem !important;
      align-items:center;
      width:100%;
    }
  
    .ag-header-cell-text {
      font-size: .8rem !important;
      color: black;
      background-color: white;
      border-style:none;
     
    }
  .ag-header{
      background-color: rgb(255, 255, 255);
  }
  
    .selectAction{
      float:right;
      vertical-align: middle;
      margin: 5px 5px;
    }
  
  
    .paginationDiv{
  
      border-width:1px 0px 0px 0px;
      border-color:lightgray;
      border-style:solid;
      text-align:center;
      font-size:.9rem !important;
      font-weight: bold;
      padding:5;

    }



    .inputBox{
        font-size:.7rem !important;
        height:20px;
        width:60px;
    }

    
    /*.ag-header-cell {
      
      border: 1px solid ;
      border-radius: 3px;
    
    }*/
  
    /*.ag-theme-alpine {}*/